import './call_bar.less';

import * as Phone                       from 'libphonenumber-js';
import * as Taptic                      from '../util/taptic';
import Button                           from '../components/button';
import classnames                       from 'classnames';
import ms                               from 'ms';
import React                            from 'react';
import useBoolean                       from '../hooks/use_boolean';

import { X as EndIcon }                 from 'react-feather';
import { MessageCircle as MessageIcon } from 'react-feather';
import { MicOff as MuteIcon }           from 'react-feather';
import { Phone as PhoneIcon }           from 'react-feather';
import { Volume2 as SpeakerIcon }       from 'react-feather';
import Row                              from '../components/row';


export default function CallBar({
  type,
  status,
  customer,
  business,
  startedAt,
  isMuted,
  isOnSpeaker,
  callerPhoneNumber,
  handleOnFinish,
  handleOnMute,
  handleOnSpeaker,
  handleOnAccept,
  handleOnDecline,
}) {
  useStatusBarStyle('light');

  const onCall       = status === 'onCall';
  const incomingCall = type === 'incoming' && status === 'ringing';

  React.useEffect(() => {
    return () => {
      callEndEvent();
    };
  }, []);

  return (
    <div className="app-banner">
      <div className="call-bar">
        <div className="call-bar-content">
          <div className="call-info">
            <CustomerInfo {...{ customer, callerPhoneNumber }}/>
            {startedAt && <Duration {...{ startedAt }}/>}
          </div>
          {incomingCall && (
            <IncomingCallButtons {...{ business, customer, handleOnDecline, handleOnAccept }}/>
          )}
          {onCall && (
            <OnCallButtons {...{ type, business, customer, isMuted, isOnSpeaker, handleOnMute, handleOnSpeaker, handleOnFinish }}/>
          )}
        </div>
      </div>
    </div>
  );
}

function CustomerInfo({ customer, callerPhoneNumber }) {
  const formattedPhoneNumber = formatPhoneNumber(callerPhoneNumber || customer?.contact?.phone?.number);
  const customerName         = customer?.name.full ?? 'Unknown';

  return (
    <div className="customer-info">
      <div className="customer-name">{customerName}</div>
      <div className="customer-phone">{formattedPhoneNumber}</div>
    </div>
  );
}


function OnCallButtons({ type, business, customer, isMuted, isOnSpeaker, handleOnMute, handleOnSpeaker, handleOnFinish }) {
  const businessID              = business?.id;
  const conversationID          = customer?.conversation?.id;
  const [ visible, setVisible ] = useBoolean(false);
  const className               = classnames('call-buttons', { visible });
  const connectingCall          = (type === 'connectingCall');

  React.useEffect(function setVisibleOnmount() {
    setTimeout(setVisible, 10);
  }, [ setVisible ]);

  return (
    <Row className={className}>
      {!connectingCall && (
        <>
          {businessID && conversationID && <Message {...{ businessID, conversationID }}/>}
          {handleOnSpeaker && <SpeakerButton onClick={handleOnSpeaker} isOnSpeaker={isOnSpeaker}/>}
          <Mute onClick={handleOnMute} isMuted={isMuted}/>
        </>
      )}
      <End onClick={handleOnFinish}/>
    </Row>
  );
}

function IncomingCallButtons({ business, customer, handleOnDecline, handleOnAccept }) {
  const [ visible, setVisible ] = useBoolean(false);
  const className               = classnames('call-buttons', { visible });

  React.useEffect(function setVisibleOnmount() {
    setTimeout(setVisible, 10);
  }, [ setVisible ]);

  return (
    <Row className={className}>
      <Decline onClick={handleOnDecline}/>
      <Accept onClick={() => handleOnAccept({ business, customer })}/>
    </Row>
  );
}


function Message({ businessID, conversationID }) {
  const path = `/${businessID}/inbox/${conversationID}`;

  return (
    <Button className="call-button message-button" href={path} iconTop={<MessageIcon/>} label="Message"/>
  );
}


function Mute({ onClick, isMuted }) {
  const active    = isMuted;
  const className = classnames('call-button', 'mute-button', { active });
  const label     = isMuted ? 'Unmute' : 'Mute';
  return (
    <Button className={className} onClick={Taptic.selection(onClick)} iconTop={<MuteIcon/>} label={label}/>
  );
}


function SpeakerButton({ onClick, isOnSpeaker }) {
  const active    = isOnSpeaker;
  const className = classnames('call-button', 'speaker-button', { active });
  return (
    <Button className={className} onClick={Taptic.selection(onClick)} iconTop={<SpeakerIcon/>} label="Speaker"/>
  );
}


function End({ onClick }) {
  return (
    <Button className="call-button end-button" onClick={Taptic.selection(onClick)} iconTop={<EndIcon/>} label="End"/>
  );
}


function Decline({ onClick }) {
  return (
    <Button className="call-button decline-button" onClick={onClick} iconTop={<PhoneIcon/>} label="Decline"/>
  );
}


function Accept({ onClick }) {
  return (
    <Button className="call-button accept-button" onClick={onClick} iconTop={<PhoneIcon/>} label="Accept"/>
  );
}

function formatPhoneNumber(number) {
  if (number)
    return Phone.format(number, 'National');
  else
    return null;
}


function Duration({ startedAt }) {
  const formattedDuration       = formatDuration(startedAt);
  const [ visible, setVisible ] = useBoolean(false);
  const className               = classnames('call-duration', { visible });

  useReRender('1s');

  React.useEffect(function setVisibleOnmount() {
    setTimeout(setVisible, 10);
  }, [ setVisible ]);

  return (
    <div className={className}>{formattedDuration}</div>
  );
}

function useReRender() {
  const [ newState, updateState ] = React.useState();
  const forceUpdate               = React.useCallback(() => updateState({}), []);

  React.useEffect(() => {
    const timer = setInterval(() => {
      forceUpdate();
    }, ms('1s'));

    return () => clearInterval(timer);
  }, [ forceUpdate ]);

  return newState;
}

function formatDuration(startedAt) {
  function format(value) {
    const valueStr = `${parseInt(value, 10)}`;
    if (valueStr.length === 1)
      return `0${valueStr}`;
    else
      return valueStr;
  }

  const diff = Date.now() - startedAt.getTime();
  if (diff >= ms('1s')) {
    const hours   = format(diff / (60 * 60 * 1000));
    const minutes = format((diff / (60 * 1000)) % 60);
    const seconds = format((diff / 1000) % 60);

    return `${hours}:${minutes}:${seconds}`;
  } else
    return '00:00:00';
}


function useStatusBarStyle(style) {
  React.useEffect(() => {
    const { StatusBar } = window;
    if (!StatusBar)
      return () => {};

    if (style === 'light')
      StatusBar.styleLightContent();
    else
      throw new Error('Unknown status bar style');

    return () => StatusBar.styleDefault();
  }, [ style ]);
}


function callEndEvent() {
  const callEvent = new window.CustomEvent(
    'callEnd',
    {
      bubbles: true,
    },
  );
  document.dispatchEvent(callEvent);
}
